<template>
  <div class="page-catalog">
    <n-loader :loading="$var('load')"></n-loader>
    <page-title title="Каталог" description="Выберете нужную вам категорию товаров" />
    <div class="container">
      <categories-item v-if="!forCreate" :item="all" :index="0" />
      <categories-item v-for="(item, i) in categories" :key="item.id" :item="item" :index="i+1" />
    </div>
  </div>
</template>

<script>
import CategoriesItem from './../components/CategoriesItem'

export default {
  name: 'PageCategoriesList',
  components: {
    CategoriesItem,
  },
  data() {
    return {
      categories: [],
    }
  },
  computed: {
    storeId() {
      return this.$route.query.store
    },
    forCreate() {
      return this.$route.query.create !== undefined
    },
    all() {
      return {
        title: 'Все категории',
        icon: 'all-categories',
        link: this.storeId ? { name: 'stores.index', params: { id: this.storeId, }, } : { name: 'products.list', },
      }
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      const api = $api.app.categories.get().filter({ isParent: true, })
      if (this.storeId) api.filter({ byStore: this.storeId, })
      if (this.forCreate) api.filter({ isForStore: $app.auth.user().hasStore || 'false', })
      api.then((response) => {
        this.categories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-catalog {

  .categories-item {
    &:nth-child(2) {
      background: #Fff !important;
      border: 1px solid #4BB222;

      .title {
        color: #4BB222;
      }
    }
  }
}
</style>
